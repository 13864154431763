export const hero = {
  title: `Your top-rated provider of point-of-sale systems in San Francisco, California`,
  subtext:
    "While many enjoy San Francisco's diverse tech community and proximity to nature, the City is also known for being at the forefront of the hospitality industry. It’s home to an unrivaled culinary scene, celebrating local, quality ingredients and global influences. Alongside the iconic Golden Gate bridge and Karl the Fog, San Francisco is home to SpotOn, located in the heart of the financial district, just a stone’s throw from the Ferry Building and Foodwise Farmers Market.",
};

export const quoteWithVideo = {
  quote: `“The whole SpotOn system is user-friendly, streamlined, and easy to set-up. Most importantly, it allows us to control our guest experience.”`,
  person: "Sam Josi",
  location: "Co-owner of Blue Barn, The Tipsy Pig, Mamanoko, and Padrecito",
  thumbnailImageName: "san-francisco-video-thumb.png",
  videoId: "OQL1DMctzmM",
};

export const textWithVideoAndImage = {
  heading: "Real users rate SpotOn as the #1 POS system",
  paragraph: "According to real user reviews submitted to Capterra, SpotOn is the top-rated point-of-sale solution for Restaurants, Bars, Retail, and Small Business. We are proud to have received the highest marks among our competition across all categories measured, including functionality, ease of use, customer support, and value for money.",
  thumbnailImageName: "",
  videoId: "",
  imageName: "locations-atlanta.png",
};

export const imageWithText = {
  heading: "POS systems to fit your business",
  paragraphs: [
    `As your business evolves, the technology you need to run it does too. SpotOn’s point-of-sale solutions in San Francisco are cloud-based with free software updates to make sure you have the latest technology to keep your business running smoothly and efficiently.`,
    `In this City of transplants, you need a POS system with software that can keep up with customers’ changing habits and can easily be tailored to your business. When your system is optimized for your unique business, transactions move more quickly, and your customers have a better experience—resulting in improved revenue and loyalty.`,
    `Whether you need a POS for restaurants, bars, or retail establishments, our team is ready to work with you on creating the customized tech solutions you need. Accept all payment types in-store and online from one catalog, track customer preferences and sales goals, produce detailed purchase reports, and send deals and promotions to your clientele, all through SpotOn’s integrated POS and software system.`,
  ],
  imageName: "san-francisco-image-1.png",
};

export const pros = {
  heading: `POS solutions in San Francisco for restaurants, bars, retail, and enterprise businesses`,
  paragraphs: [
    `When setting up your POS system, you need a system that can keep up with your customers’ evolving expectations for service and technology. SpotOn’s top-rated POS software in San Francisco offers a customized setup to meet your business needs, and we back it up with superior 24/7/365 customer service. No matter when you run into a problem, we’re here to troubleshoot it for you.`,
    `Whether you run a record shop in the Mission or a seafood restaurant on The Embarcadero, you deserve a POS system that can help you increase revenue, streamline operations, manage and retain employees, and create better customer experiences.`,
    `If your business takes part in one of the many Saturday Farmers’ Markets in San Francisco, mobility and customization are also essential. Your POS solution must be able to function on the go, while still delivering a seamless customer experience.`,
  ],
  bulletsTitle: `Here are some of the many industries that benefit from SpotOn’s POS system in San Francisco, California:`,
  bullets: [
    `Restaurants, from fine dining to quick service`,
    `Bars, wine bars, distilleries, and breweries`,
    `Retail, from clothing to home decor`,
    `Food trucks and street vendors`,
    `Professional services such as accounting`,
    `Local services such as automotive or health and beauty`,
    `Enterprise businesses, such as educational campuses, sports and entertainment, amusement and attractions, and more`,
  ],
  imageName: "san-francisco-image-2.png",
};

// export const mainFeature = {
//   heading: `With SpotOn’s POS for bars and restaurants, you’re better equipped to take and send orders in real-time. The system also offers complete, robust reporting on sales and labor, and tools to help expand your marketing reach.`,
//   imageName: "san-diego-image-3.png",
// };

export const pros2 = {
  heading: `Looking for a POS for restaurants`,
  topText:
    "Manage and track the essential functions of your restaurant in real-time, including:",
  bottomText: `These easy-to-use tools make SpotOn’s <a href="/restaurant-pos" class="text-primary">restaurant POS</a> ideal for San Francisco’s booming hospitality industry.`,
  bullets: [
    `Payments, including touchless digital payments`,
    `Table & waitlist management`,
    `Fast, affordable, and secure payment processing`,
    `Reports on daily sales or most popular dishes at a glance`,
    `Menu modifications or 86’ing items on the fly`,
    `Item availability`,
    `Customer promotions and specials`,
    `Membership discounts and loyalty programs`,
    `Team scheduling and tip distribution`,
    `Email and social media marketing`,
    `QR, mobile, and handheld order-taking and payment options`,
  ],
  imageName: "san-diego-image-4.png",
};

export const footer = {
  heading: `If you’re looking for POS solutions in San Francisco and want to learn more about what SpotOn’s POS system can do for your business, contact us today for a free demo`,
  paragraphs: [
    `SpotOn’s 24/7 live support keeps you in touch with experts no matter the time or location. Prefer to troubleshoot or train in person? We’ve got you! Our local team is happy to roll up their sleeves, jump behind the counter, and give you the support you deserve.`,
    `If you’re looking for POS solutions in San Francisco and want to learn more about what SpotOn’s POS system can do for your business, contact us today for a <a href="/demo" class="text-primary">free demo</a>. Upon sign up, SpotOn provides new San Francisco restaurants (and other select cities) with a free photoshoot through Snappr, a nationwide network of photographers that serve enterprise clients like Doordash, Toast, PopMenu, and Bentobox. <a href="https://spotonteam.atlassian.net/wiki/spaces/SPO/pages/2118483969/Snappr+Custom+Photography+-+Pilot" class="text-primary">More information on the Snappr & SpotOn partnership here.</a>`,
  ],
  cta: {
    target: "#form",
    title: "Learn more",
  },
};

export const terms = {
  title: "Snappr terms & conditions.",
  paragraphs: [
    `The Snappr promotion offered through SpotOn is only available to those customers purchasing for their own personal or their company's use in the cities of Atlanta, Georgia, San Francisco, California, and San Diego, California, from 7/1/2022 to 12/31/2022. These images produced during the promotion are intended for personal or company promotional use and not intended for any sort of resale. All promotions are non-transferrable. Promotional offers end at 11:59 PM on the expiration date unless indicated otherwise and are available only to U.S. residents, exclusively provided through the Snappr photography company.`,
    `Any discounts, prizes, or promotional considerations applicable to this order apply only to the initial purchase and purchase price. They are not applicable to other charges, including: previous purchases, current or existing monthly fees, unpaid balances, or any future charges related to the product(s).`,
  ],
};
