import React from "react";
import { string, arrayOf, shape } from "prop-types";
import Section from "../Base/Section";
import Heading from "../Base/HeadingBuilder";
import CtaPrimary from "../Base/CtaPrimary";

import circles from "../../images/svg/double-circle-top-right-small.svg";
import quotes from "../../images/svg/quotes-blue.svg";

const OverviewFooter = ({ className, heading, paragraphs, cta, title }) => {
  return (
    <Section
      className={`
        flex flex-col lg:flex-row justify-between items-center
        gap-10 lg:gap-16 xl:gap-[120px]
        ${className}
      `}
    >
      <div className="w-full">
      {title && (
          <Heading level={3} injectionType={3}>
            {title}
          </Heading>
          )}
        {paragraphs.map((p) => (
          <p
            key={p}
            className="text-p text-black-300 max-w-[560px]"
            dangerouslySetInnerHTML={{ __html: p }}
          />
        ))}
      </div>

      <div className="w-full">
        <div
          className={`relative
            lg:max-w-[450px] bg-primary-200 rounded-[24px] overflow-hidden
            px-6 md:px-20 lg:px-10 xl:px-20
            py-20 
          `}
        >
          <img
            alt="background"
            src={quotes}
            className="absolute top-0 right-10 max-w-[130px]"
          />
          <img
            alt="background"
            src={circles}
            className="absolute bottom-0 left-0 max-w-[60px]"
          />
          <Heading
            level={4}
            injectionType={4}
            withBlueSymbol
            className="text-white relative"
          >
            {heading}
          </Heading>

          <CtaPrimary className="mt-8 relative" {...cta} />
        </div>
      </div>
    </Section>
  );
};

OverviewFooter.propTypes = {
  className: string,
  heading: string.isRequired,
  title: string,
  paragraphs: arrayOf(string).isRequired,
  cta: shape({
    title: string,
    target: string,
  }),
};

OverviewFooter.defaultProps = {
  className: "",
  cta: null,
  title: "",
};

export default OverviewFooter;
